import React from 'react'
// import { vars } from 'theme/sprinkles.css'

const LogoColorMapping: Record<LogoColors, string> = {
  // TODO maybe there is a way to use color vars from theme. Need to explore
  green: '#00BF6F',
  white: '#fff',
  black: '#000',
}

type LogoColors = 'green' | 'white' | 'black'
interface XericLogoProps extends React.HTMLAttributes<SVGElement> {
  text?: boolean
  equalHeight?: boolean
  iconColor?: LogoColors
  textColor?: LogoColors
}

/**
 * Pure SVG logo component
 */
export default function XericLogo({
  text = false,
  equalHeight,
  iconColor = 'green',
  textColor = 'green',
  ...props
}: XericLogoProps) {
  // not typesafe, but both are strings.
  iconColor = LogoColorMapping[iconColor] as LogoColors
  textColor = LogoColorMapping[textColor] as LogoColors

  if (text && equalHeight) {
    return (
      <svg width="128" height="36" viewBox="0 0 128 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_1_61)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.26174 12.728V5.27212H12.703L17.9649 0L23.2267 5.27212H30.668V12.7279L35.9298 18L30.668 23.2721V30.7279H23.2267L17.9649 36L12.7031 30.7279H5.26174V23.272L0 18L5.26174 12.728ZM16.0656 13.4058C16.9294 11.4586 16.5644 9.09681 14.9708 7.50002C14.4291 6.95729 13.7992 6.55678 13.1274 6.2985C12.8346 6.95712 12.6719 7.68657 12.6719 8.4541C12.6719 10.7124 14.0806 12.6409 16.0656 13.4058ZM13.3795 16.097C12.6162 14.1082 10.6914 12.6967 8.43751 12.6967C7.67151 12.6967 6.94352 12.8598 6.28621 13.1531C6.54399 13.8262 6.94371 14.4573 7.48537 15C9.07904 16.5968 11.4362 16.9625 13.3795 16.097ZM6.28619 22.847C6.54397 22.1738 6.94369 21.5427 7.48537 21C9.07905 19.4032 11.4362 19.0375 13.3795 19.903C12.6162 21.8918 10.6914 23.3033 8.43751 23.3033C7.67151 23.3033 6.94351 23.1403 6.28619 22.847ZM13.1274 29.7015C12.8346 29.0429 12.6719 28.3135 12.6719 27.546C12.6719 25.2877 14.0806 23.3591 16.0656 22.5943C16.9294 24.5415 16.5644 26.9032 14.9708 28.5C14.4291 29.0428 13.7992 29.4432 13.1274 29.7015ZM22.8023 29.7015C22.1305 29.4432 21.5007 29.0427 20.959 28.5C19.3653 26.9032 19.0004 24.5415 19.8642 22.5943C21.8491 23.3592 23.2578 25.2877 23.2578 27.546C23.2578 28.3134 23.0951 29.0429 22.8023 29.7015ZM29.6436 22.8469C28.9863 23.1403 28.2583 23.3033 27.4922 23.3033C25.2384 23.3033 23.3135 21.8919 22.5502 19.903C24.4935 19.0375 26.8507 19.4031 28.4444 21C28.9861 21.5427 29.3858 22.1738 29.6436 22.8469ZM29.6436 13.1531C29.3858 13.8262 28.9861 14.4572 28.4445 14.9999C26.8508 16.5968 24.4936 16.9624 22.5503 16.0969C23.3136 14.1081 25.2384 12.6967 27.4922 12.6967C28.2582 12.6967 28.9862 12.8598 29.6436 13.1531ZM22.8023 6.29852C23.0951 6.95713 23.2578 7.68658 23.2578 8.4541C23.2578 10.7123 21.8491 12.6409 19.8642 13.4057C19.0004 11.4586 19.3653 9.09681 20.959 7.49997C21.5007 6.95728 22.1305 6.5568 22.8023 6.29852Z"
            fill={iconColor}
          />
        </g>
        <path
          d="M82.7166 28.4017H88.0735V21.5126C88.0735 18.4109 89.8085 16.5917 93.4609 16.5917C94.4652 16.5917 95.4393 16.8004 96.4133 17.0688V12.148C95.8045 12.0287 95.0741 11.999 94.4652 11.999C91.4217 11.999 89.8998 13.1024 87.9519 15.5478H87.8909V12.4164H82.7166V28.4017Z"
          fill={textColor}
        />
        <path
          d="M125.754 18.3215C124.75 13.0427 119.91 11.8201 115.771 11.8201C109.319 11.8201 104.997 14.3848 104.997 20.4092C104.997 26.4335 109.166 28.9983 115.315 28.9983C120.641 28.9983 124.78 27.7159 125.754 22.0495H120.215C119.941 24.0477 118.145 25.3001 115.771 25.3001C112.454 25.3001 110.536 23.5406 110.536 20.4092C110.536 17.2776 112.454 15.5181 115.771 15.5181C117.932 15.5181 119.576 16.2041 120.215 18.3215H125.754Z"
          fill={textColor}
        />
        <path
          d="M35.9298 28.4018H43.2651L48.5612 21.2145L53.8571 28.4018H61.1923L51.97 17.4268L60.4314 7.10791H53.3397L48.5612 13.7288L43.7824 7.10791H36.6908L45.1522 17.4268L35.9298 28.4018Z"
          fill={textColor}
        />
        <path
          d="M75.0367 23.6301C74.276 25.0019 72.7541 25.7177 70.5931 25.7177C66.9407 25.7177 65.3578 23.3916 65.3578 21.8705V21.8108H81.0632V21.3337C81.0632 15.3094 77.6544 11.8201 70.5931 11.8201C64.1404 11.8201 59.8184 14.3848 59.8184 20.4092C59.8184 26.4335 64.1404 28.9983 70.5931 28.9983C75.4934 28.9983 79.2066 27.4177 80.759 23.6301H75.0367ZM65.3578 18.7092V18.6496C65.3578 18.2618 66.2406 15.1006 70.4713 15.1006C74.3976 15.1006 75.5238 17.7847 75.5238 18.6496V18.7092H65.3578Z"
          fill={textColor}
        />
        <path
          d="M97.9686 28.4018H103.325V12.4164H97.9686V28.4018ZM97.9686 10.4482H103.325V6.75H97.9686V10.4482Z"
          fill={textColor}
        />
        <defs>
          <clipPath id="clip0_1_61">
            <rect width="35.9298" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }

  if (text) {
    return (
      <svg width="935" height="400" viewBox="0 0 935 400" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M258.925 93.934C258.925 126.478 232.543 152.86 199.999 152.86C167.456 152.86 141.074 126.478 141.074 93.934C141.074 80.6686 145.457 68.427 152.854 58.5786H58.578V152.855C68.4264 145.458 80.6679 141.074 93.9333 141.074C126.477 141.074 152.859 167.456 152.859 200C152.859 232.544 126.477 258.925 93.9333 258.925C80.6679 258.925 68.4264 254.542 58.578 247.145V341.421H152.855C145.457 331.573 141.074 319.331 141.074 306.066C141.074 273.522 167.456 247.14 199.999 247.14C232.543 247.14 258.925 273.522 258.925 306.066C258.925 319.331 254.541 331.573 247.144 341.421H341.421V247.145C331.572 254.542 319.331 258.925 306.066 258.925C273.522 258.925 247.14 232.544 247.14 200C247.14 167.456 273.522 141.074 306.066 141.074C319.331 141.074 331.572 145.458 341.421 152.855V58.5786H247.144C254.541 68.427 258.925 80.6686 258.925 93.934Z"
          fill={iconColor}
        />
        <path
          d="M166.667 83.3335C189.678 106.345 189.678 143.655 166.667 166.667C143.655 189.679 106.345 189.679 83.3332 166.667C73.9531 157.287 68.3966 145.531 66.6635 133.337L0 200L66.6635 266.663C68.3966 254.469 73.9531 242.713 83.3332 233.333C106.345 210.321 143.655 210.321 166.667 233.333C189.678 256.345 189.678 293.655 166.667 316.667C157.286 326.047 145.531 331.603 133.336 333.336L200 400L266.664 333.336C254.469 331.603 242.713 326.047 233.333 316.667C210.321 293.655 210.321 256.345 233.333 233.333C256.345 210.321 293.654 210.321 316.666 233.333C326.047 242.713 331.603 254.469 333.336 266.664L400 200L333.336 133.336C331.603 145.531 326.047 157.286 316.667 166.666C293.655 189.678 256.345 189.678 233.333 166.666C210.321 143.655 210.321 106.345 233.333 83.333C242.713 73.9531 254.469 68.3965 266.663 66.6634L200 0L133.336 66.6637C145.531 68.3968 157.286 73.9534 166.667 83.3335Z"
          fill={iconColor}
        />
        <path
          d="M374 268.273H419.953L453.131 223.369L486.308 268.273H532.261L474.486 199.705L527.494 135.236H483.067L453.131 176.601L423.194 135.236H378.767L431.775 199.705L374 268.273Z"
          fill={textColor}
        />
        <path
          d="M622.431 238.461C617.665 247.032 608.131 251.504 594.593 251.504C571.712 251.504 561.796 236.971 561.796 227.468V227.095H660.185V224.114C660.185 186.476 638.83 164.676 594.593 164.676C554.169 164.676 527.093 180.7 527.093 218.338C527.093 255.976 554.169 272 594.593 272C625.292 272 648.554 262.125 658.279 238.461H622.431ZM561.796 207.717V207.345C561.796 204.922 567.326 185.172 593.83 185.172C618.427 185.172 625.482 201.941 625.482 207.345V207.717H561.796Z"
          fill={textColor}
        />
        <path
          d="M669.683 268.273H703.242V225.232C703.242 205.854 714.111 194.488 736.992 194.488C743.284 194.488 749.386 195.792 755.488 197.469V166.725C751.674 165.98 747.098 165.794 743.284 165.794C724.217 165.794 714.683 172.688 702.48 187.966H702.098V168.402H669.683V268.273Z"
          fill={textColor}
        />
        <path
          d="M760.932 268.273H794.491V168.402H760.932V268.273ZM760.932 156.105H794.491V133H760.932V156.105Z"
          fill={textColor}
        />
        <path
          d="M935 205.295C928.708 172.315 898.39 164.676 872.458 164.676C832.035 164.676 804.959 180.7 804.959 218.338C804.959 255.976 831.082 272 869.598 272C902.966 272 928.898 263.988 935 228.586H900.297C898.581 241.07 887.331 248.895 872.458 248.895C851.675 248.895 839.662 237.902 839.662 218.338C839.662 198.773 851.675 187.78 872.458 187.78C885.996 187.78 896.293 192.066 900.297 205.295H935Z"
          fill={textColor}
        />
      </svg>
    )
  }

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1028 1028"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={iconColor}
        d="M665.4,241.4c0,83.6-67.8,151.4-151.4,151.4S362.6,325,362.6,241.4c0-34.1,11.3-65.6,30.3-90.9H150.5v242.3
	c25.3-19,56.8-30.3,90.9-30.3c83.6,0,151.4,67.8,151.4,151.4S325,665.4,241.4,665.4c-34.1,0-65.6-11.3-90.9-30.3v242.3h242.3
	c-19-25.3-30.3-56.8-30.3-90.9c0-83.6,67.8-151.4,151.4-151.4S665.4,703,665.4,786.6c0,34.1-11.3,65.6-30.3,90.9h242.3V635.2
	c-25.3,19-56.8,30.3-90.9,30.3c-83.6,0-151.4-67.8-151.4-151.4S703,362.6,786.6,362.6c34.1,0,65.6,11.3,90.9,30.3V150.5H635.2
	C654.2,175.9,665.4,207.3,665.4,241.4z"
      />
      <path
        fill={iconColor}
        d="M428.3,214.2c59.1,59.1,59.1,155,0,214.2s-155,59.1-214.2,0c-24.1-24.1-38.4-54.3-42.8-85.7L0,514l171.3,171.3
	c4.5-31.3,18.7-61.6,42.8-85.7c59.1-59.1,155-59.1,214.2,0c59.1,59.1,59.1,155,0,214.2c-24.1,24.1-54.3,38.4-85.7,42.8L514,1028
	l171.3-171.3c-31.3-4.5-61.6-18.7-85.7-42.8c-59.1-59.1-59.1-155,0-214.2c59.1-59.1,155-59.1,214.2,0c24.1,24.1,38.4,54.3,42.8,85.7
	L1028,514L856.7,342.7c-4.5,31.3-18.7,61.6-42.8,85.7c-59.1,59.1-155,59.1-214.2,0c-59.1-59.1-59.1-155,0-214.2
	c24.1-24.1,54.3-38.4,85.7-42.8L514,0L342.7,171.3C374,175.8,404.2,190.1,428.3,214.2z"
      />
    </svg>
  )
}
