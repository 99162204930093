import clsx from 'clsx'
import { Box } from 'components/Box'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { useCloseModal, useModalIsOpen, useOpenModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { defaultTheme } from 'theme/themes/default.css'

export default function TestModal() {
  const isOpen = useModalIsOpen(ApplicationModal.WALLET)
  const openModal = useOpenModal(ApplicationModal.WALLET)
  const closeModal = useCloseModal()
  // const toggleModal = useToggleModal(ApplicationModal.WALLET)

  return (
    <>
      <Modal isOpen={isOpen} onDismiss={closeModal} maxHeight={100}>
        <Box className={clsx(defaultTheme)} style={{ padding: '65px 70px' }}>
          <Box style={{ textAlign: 'center' }}>
            <Typography variant="displaySM">Connect Wallet</Typography>
            <Typography variant="textMDRegular">
              By connecting your wallet, you agree to our Terms of Service and our Privacy Policy.
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Button onClick={openModal} variant="primary" size="sm">
        open modal
      </Button>
    </>
  )
}
