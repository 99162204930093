import clsx, { ClassValue } from 'clsx'
import * as React from 'react'

import c, { ButtonVariants } from './Button.css'

export type ButtonVariant = 'ghost' | 'outline' | 'tertiary' | 'secondary' | 'primary'
export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl' | 'xl2'

type ButtonProps = ButtonVariants & {
  size: ButtonSize
  variant: ButtonVariant
  // disabled?: boolean;
  // loading?: boolean

  startDecorator?: React.ReactNode
  endDecorator?: React.ReactNode
  startDecoratorProps?: React.HTMLAttributes<HTMLDivElement>
  endDecoratorProps?: React.HTMLAttributes<HTMLDivElement>
  className?: ClassValue

  children?: React.ReactNode

  onClick?: () => void
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const {
    variant,
    size,
    children,
    startDecorator,
    startDecoratorProps,
    endDecorator,
    endDecoratorProps,
    className,
    onClick,
  } = props

  return (
    <button
      ref={ref}
      className={clsx(
        c.button({
          size,
          variant,
        }),
        className,
        !children && c.noText
      )}
      onClick={onClick}
    >
      <div className={c.contentWrapper}>
        <div className={c.content({ size, variant })}>
          {startDecorator && (
            <div className={c.startDecorator({ size })} {...startDecoratorProps}>
              {startDecorator}
            </div>
          )}
          {children}
          {endDecorator && (
            <div className={c.endDecorator({ size })} {...endDecoratorProps}>
              {endDecorator}
            </div>
          )}
        </div>
      </div>
    </button>
  )
})

export default Button
