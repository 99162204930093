import { Box } from 'components/Box'
import Typography from 'components/Typography'
import * as React from 'react'
import { theme } from 'theme'

import Cards from './Cards'
import * as styles from './Home.css'
import { ReactComponent as TwistOverlay } from './twist.svg'

export default function Home() {
  return (
    <div className={styles.home}>
      <TwistOverlay className={styles.twist} />
      <div className={styles.content}>
        <Box display="flex" alignItems="center" justifyContent={'center'} className={styles.bannerContainer}>
          <Cards />

          <Box display={'flex'} flexDirection="column" gap="20" className={styles.bannerRight}>
            <Typography variant="displayXL">The first social NFT aggregator</Typography>
            <Typography variant="textLGRegular" color={theme.palette.grey[800]} style={{ width: '85%' }}>
              Welcome to Xeric, the place to discover, buy, and sell NFTs across marketplaces, connect with others and
              save on gas fees, big time.
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  )
}
