import TestComponent from 'components/TestComponent'

import * as styles from './Dev.css'

export default function Dev() {
  return (
    <div className={styles.dev}>
      <TestComponent />
    </div>
  )
}
