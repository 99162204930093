import '@reach/dialog/styles.css'
import 'polyfills'

import Web3Provider from 'components/_chains/evm/Web3Provider'
import { BlockNumberProvider } from 'lib/ethereum/hooks/useBlockNumber'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import store from 'state'

import App from './pages/App'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <Web3Provider>
            <BlockNumberProvider>
              <App />
            </BlockNumberProvider>
          </Web3Provider>
        </HashRouter>
      </QueryClientProvider>
    </Provider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
