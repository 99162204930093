import * as React from 'react'

import * as styles from './Card.css'
import placeholder from './placeholder.png'
import placeholder2 from './placeholder-2.png'
import placeholder3 from './placeholder-3.png'

function Card({ imageUrl, alt, id, rotation }: { imageUrl: string; alt: string; id: string; rotation: number }) {
  return (
    <img
      className={styles.card}
      src={imageUrl}
      alt={alt}
      id={id}
      style={{
        transform: `${rotation !== 0 && `translate(-50%, -50%)`} rotate(${rotation}deg)`,
      }}
    />
  )
}

export default function Cards() {
  return (
    <div className={styles.cards}>
      <Card imageUrl={placeholder2} alt="placeholder" id="placeholder-nft" rotation={0} />
      <Card imageUrl={placeholder} alt="placeholder" id="placeholder-nft" rotation={15} />
      <Card imageUrl={placeholder3} alt="placeholder" id="placeholder-nft" rotation={-15} />
    </div>
  )
}
