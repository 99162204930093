import extractEventHandlers from 'lib/utils/extractEventHandlers'
import * as React from 'react'

export interface UseInputParameters {
  /**
   * The default value. Use when the component is not controlled.
   */
  defaultValue?: unknown
  /**
   * If `true`, the component is disabled.
   * The prop defaults to the value (`false`) inherited from the parent FormControl component.
   */
  disabled?: boolean
  /**
   * If `true`, the `input` will indicate an error.
   * The prop defaults to the value (`false`) inherited from the parent FormControl component.
   */
  error?: boolean
  onBlur?: React.FocusEventHandler
  onClick?: React.MouseEventHandler
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler
  inputRef?: React.Ref<HTMLInputElement>
  /**
   * If `true`, the `input` element is required.
   * The prop defaults to the value (`false`) inherited from the parent FormControl component.
   */
  required?: boolean
  value?: unknown
}

export interface UseInputRootSlotOwnProps {
  onClick: React.MouseEventHandler | undefined
}

export type UseInputRootSlotProps<TOther = Record<string, unknown>> = Omit<
  TOther,
  keyof UseInputRootSlotOwnProps | 'onBlur' | 'onChange' | 'onFocus'
> &
  UseInputRootSlotOwnProps

export interface UseInputInputSlotOwnProps {
  'aria-invalid': React.AriaAttributes['aria-invalid']
  defaultValue: string | number | readonly string[] | undefined
  value: string | number | readonly string[] | undefined
  onBlur: React.FocusEventHandler
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onFocus: React.FocusEventHandler
  required: boolean
  disabled: boolean
}

export type UseInputInputSlotProps<TOther = Record<string, unknown>> = Omit<TOther, keyof UseInputInputSlotOwnProps> &
  UseInputInputSlotOwnProps

export default function useInput(parameters: UseInputParameters) {
  const {
    defaultValue: defaultValueProp,
    disabled: disabledProp = false,
    error: errorProp = false,
    onBlur,
    onChange,
    onFocus,
    required: requiredProp = false,
    value: valueProp,
  } = parameters

  const defaultValue = defaultValueProp
  const disabled = disabledProp
  const error = errorProp
  const required = requiredProp
  const value = valueProp

  const { current: isControlled } = React.useRef(value != null)

  const inputRef = React.useRef<HTMLInputElement>(null)

  const [focused, setFocused] = React.useState(false)

  // The blur won't fire when the disabled state is set on a focused input.
  // We need to book keep the focused state manually.
  React.useEffect(() => {
    if (disabled && focused) {
      setFocused(false)

      // @ts-ignore
      onBlur?.()
    }
  }, [disabled, focused, onBlur])

  const handleFocus =
    (otherHandlers: Record<string, React.EventHandler<any> | undefined>) =>
    (event: React.FocusEvent<HTMLInputElement>) => {
      // Fix a bug with IE11 where the focus/blur events are triggered
      // while the component is disabled.
      if (disabled) {
        event.stopPropagation()
        return
      }

      otherHandlers.onFocus?.(event)

      setFocused(true)
    }

  const handleBlur =
    (otherHandlers: Record<string, React.EventHandler<any> | undefined>) =>
    (event: React.FocusEvent<HTMLInputElement>) => {
      otherHandlers.onBlur?.(event)

      setFocused(false)
    }

  const handleChange =
    (otherHandlers: Record<string, React.EventHandler<any> | undefined>) =>
    (event: React.ChangeEvent<HTMLInputElement>, ...args: unknown[]) => {
      if (!isControlled) {
        const element = event.target || inputRef.current
        if (element == null) {
          throw new Error(
            'MUI: Expected valid input target. ' +
              'Did you use a custom `components.Input` and forget to forward refs? ' +
              'See https://mui.com/r/input-component-ref-interface for more info.'
          )
        }
      }

      // @ts-ignore
      otherHandlers.onChange?.(event, ...args)
    }

  const handleClick =
    (otherHandlers: Record<string, React.EventHandler<any>>) => (event: React.MouseEvent<HTMLInputElement>) => {
      if (inputRef.current && event.currentTarget === event.target) {
        inputRef.current.focus()
      }

      otherHandlers.onClick?.(event)
    }

  const getRootProps = <TOther extends Record<string, any> = Record<string, unknown>>(
    externalProps: TOther = {} as TOther
  ): UseInputRootSlotProps<TOther> => {
    // onBlur, onChange and onFocus are forwarded to the input slot.
    const propsEventHandlers = extractEventHandlers(parameters, ['onBlur', 'onChange', 'onFocus'])
    const externalEventHandlers = { ...propsEventHandlers, ...extractEventHandlers(externalProps) }

    return {
      ...externalProps,
      ...externalEventHandlers,
      onClick: handleClick(externalEventHandlers),
    }
  }

  const getInputProps = <TOther extends Record<string, any> = Record<string, unknown>>(
    externalProps: TOther = {} as TOther
  ): UseInputInputSlotProps<TOther> => {
    const propsEventHandlers: Record<string, React.EventHandler<any> | undefined> = {
      onBlur,
      onChange,
      onFocus,
    }

    const externalEventHandlers = { ...propsEventHandlers, ...extractEventHandlers(externalProps) }

    const mergedEventHandlers = {
      ...externalProps,
      ...externalEventHandlers,
      onBlur: handleBlur(externalEventHandlers),
      onChange: handleChange(externalEventHandlers),
      onFocus: handleFocus(externalEventHandlers),
    }

    return {
      ...mergedEventHandlers,
      'aria-invalid': error || undefined,
      defaultValue: defaultValue as string | number | readonly string[] | undefined,
      value: value as string | number | readonly string[] | undefined,
      required,
      disabled,
    }
  }

  return {
    disabled,
    error,
    focused,
    getInputProps,
    getRootProps,
    required,
    value,
  }
}
