import * as React from 'react'
import { SVGProps } from 'react'

const SvgXClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18 6 6 18M6 6l12 12" stroke={props.color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default SvgXClose
