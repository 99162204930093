import { Box } from 'components/Box'
import Button from 'components/Button'
import SearchInput from 'components/SearchInput'
import XericLogo from 'components/XericLogo'
import { ShoppingBag01 } from 'lib/icons/finance'
import { HelpOctagon } from 'lib/icons/general'
import * as React from 'react'
import { theme } from 'theme'

import { styles } from './Navbar.css'

const Navbar = React.forwardRef(function navbar() {
  return (
    <nav className={styles.nav}>
      <Box className={styles.leftContainer}>
        <Box as="div" display={'flex'} alignItems="center" justifyContent="flex-start" flexWrap="nowrap" height="full">
          <div className={styles.logoContainer}>
            <XericLogo equalHeight text iconColor="green" textColor="black" className={styles.logo} />
          </div>
          <SearchInput />
        </Box>
      </Box>
      <Box className={styles.rightSideContainer}>
        <Box display="flex">
          <Button className={styles.connectWalletButton} size="lg" variant="primary">
            Connect Wallet
          </Button>
        </Box>
        <Box display="flex">
          <Button size="lg" variant="ghost" startDecorator={<ShoppingBag01 color={theme.palette.black} />} />
        </Box>
        <Box display="flex">
          <Button size="lg" variant="ghost" startDecorator={<HelpOctagon color={theme.palette.black} />} />
        </Box>
      </Box>
    </nav>
  )
})

export default Navbar
