import Input from 'components/Input'
import SearchIcon from 'lib/icons/general/SearchMd'
import CloseIcon from 'lib/icons/general/XClose'
import * as React from 'react'
import ReactDOM from 'react-dom'

import * as styles from './SearchInput.css'

function SearchInput() {
  const [focused, setFocused] = React.useState(false)

  const handleFocus = React.useCallback(() => {
    setFocused(true)
  }, [setFocused])

  const handleBlur = React.useCallback(() => {
    setFocused(false)
  }, [setFocused])
  const wrapper = document.getElementById('root')

  if (wrapper === null) {
    return null
  }
  return (
    <>
      {ReactDOM.createPortal(
        <div id="backdrop" className={styles.backdropVariants[focused ? 'shown' : 'hidden']}></div>,
        wrapper
      )}
      <Input
        onFocus={handleFocus}
        onBlur={handleBlur}
        startDecorator={<SearchIcon stroke="currentColor" color="currentColor" fontSize="inherit" />}
        endDecorator={
          <CloseIcon
            className={styles.closeIcon[focused ? 'visible' : 'hidden']}
            stroke="inherit"
            color="inherit"
            fill="inherit"
          />
        }
        fullWidth
        size="medium"
        className={styles.input}
        placeholder="Search the metaverse"
      />
    </>
  )
}

export default SearchInput
