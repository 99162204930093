import '../theme/global.css'

import clsx from 'clsx'
import { Box } from 'components/Box'
import ErrorBoundary from 'components/ErrorBoundary'
import Loader from 'components/Loader'
import Navbar from 'components/Navbar'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { defaultTheme } from 'theme/themes/default.css'

import * as styles from './App.css'
import Dev from './Dev'
import Home from './Home'

export default function App() {
  return (
    <ErrorBoundary>
      <Box className={clsx(styles.appWrapper, defaultTheme)}>
        <Navbar />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="home" element={<Home />} />
          </Routes>
          <Routes>
            <Route path="dev" element={<Dev />} />
          </Routes>
        </Suspense>
      </Box>
    </ErrorBoundary>
  )
}
