import Button, { ButtonVariant } from 'components/Button'
import TestModal from 'components/TestModal'
import Typography from 'components/Typography'
import CheckCircleIcon from 'lib/icons/general/CheckCircle'
import { textMdBold, textMdRegular } from 'theme/text.css'

import { connectWalletButton, customButton, testTextWrapper } from './TestComponent.css'

const variants: Array<ButtonVariant> = ['ghost', 'outline', 'tertiary', 'secondary', 'primary']

export default function TestComponent() {
  return (
    <div className={testTextWrapper}>
      modified text placeholder
      <div className={textMdRegular}>Text MD Regular</div>
      <div className={textMdBold}>Text MD Bold</div>
      <Typography variant="displayMD">Test</Typography>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="sm"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            endDecorator={<CheckCircleIcon color="currentColor" />}
            key={`sm-${variant}`}
          >
            sm {variant}
          </Button>
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="md"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            endDecorator={<CheckCircleIcon color="currentColor" />}
            key={`md-${variant}`}
          >
            md {variant}
          </Button>
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="lg"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            endDecorator={<CheckCircleIcon color="currentColor" />}
            key={`lg-${variant}`}
          >
            lg {variant}
          </Button>
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="xl"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            endDecorator={<CheckCircleIcon color="currentColor" />}
            key={`xl-${variant}`}
          >
            xl {variant}
          </Button>
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="xl2"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            endDecorator={<CheckCircleIcon color="currentColor" />}
            key={`xl2-${variant}`}
          >
            xl2 {variant}
          </Button>
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="sm"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            key={`sm-${variant}`}
          />
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="md"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            key={`md-${variant}`}
          />
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="lg"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            key={`lg-${variant}`}
          />
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="xl"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            key={`xl-${variant}`}
          />
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        {variants.map((variant: ButtonVariant) => (
          <Button
            size="xl2"
            variant={variant}
            startDecorator={<CheckCircleIcon color="currentColor" />}
            key={`xl2-${variant}`}
          />
        ))}
      </div>
      <div style={{ flex: 'none', marginBottom: 20 }}>
        <Button
          className={customButton}
          size="md"
          variant="primary"
          startDecorator={<CheckCircleIcon color="currentColor" />}
        >
          custom button
        </Button>
        <Button className={connectWalletButton} size="lg" variant="primary">
          Connect Wallet
        </Button>
        <TestModal />
      </div>
    </div>
  )
}
