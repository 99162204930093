import clsx from 'clsx'
import useInput from 'hooks/useInput'
import * as React from 'react'

import * as inputStyles from './Input.css'

interface InputProps extends React.HTMLAttributes<HTMLDivElement> {
  inputProps?: React.HTMLAttributes<HTMLInputElement>
  startDecorator?: React.ReactNode
  endDecorator?: React.ReactNode
  startDecoratorProps?: React.HTMLAttributes<HTMLDivElement>
  endDecoratorProps?: React.HTMLAttributes<HTMLDivElement>

  fullWidth?: boolean
  error?: boolean
}

// Todo: add event handlers
const Input = React.forwardRef(function Input(
  {
    'aria-describedby': ariaDescribedby,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    autoComplete,
    autoFocus,
    inputProps,
    className,
    defaultValue,
    disabled: disabledProp,
    endDecorator,
    error: errorProp,
    fullWidth,
    id,
    name,
    onClick,
    onChange,
    onKeyDown,
    onKeyUp,
    onFocus,
    onBlur,
    placeholder,
    readOnly,
    required,
    type,
    value,
    startDecorator,
    startDecoratorProps,
    endDecoratorProps,
    size = 'medium',
    ...props
  }: InputProps &
    Pick<
      React.InputHTMLAttributes<HTMLInputElement>,
      | 'autoComplete'
      | 'autoFocus'
      | 'onClick'
      | 'onChange'
      | 'onKeyDown'
      | 'onKeyUp'
      | 'onFocus'
      | 'onBlur'
      | 'defaultValue'
      | 'value'
      | 'type'
      | 'placeholder'
      | 'readOnly'
      | 'required'
      | 'name'
      | 'id'
      | 'disabled'
    > &
    inputStyles.WrapperVariants,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { getRootProps, getInputProps, focused, error, disabled } = useInput({
    disabled: disabledProp,
    defaultValue,
    error: errorProp,
    onBlur,
    onClick,
    onChange,
    onFocus,
    required,
    value,
  })

  const propsToForward = {
    'aria-describedby': ariaDescribedby,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    autoComplete,
    autoFocus,
    id,
    onKeyDown,
    onKeyUp,
    name,
    placeholder,
    readOnly,
    type,
  }

  return (
    <div
      ref={ref}
      className={clsx(
        inputStyles.inputWrapper({
          size,
          width: fullWidth ? 'fullWidth' : undefined,
          state: focused ? 'focused' : error ? 'error' : undefined,
        }),
        className
      )}
      {...getRootProps({ ...props })}
    >
      {startDecorator && (
        <div className={inputStyles.startDecorator} {...startDecoratorProps}>
          {startDecorator}
        </div>
      )}
      <input
        {...getInputProps({ ...propsToForward, ...inputProps })}
        className={clsx(inputStyles.input, inputProps?.className)}
      />
      {endDecorator && (
        <div
          {...endDecoratorProps}
          className={inputStyles.endDecoratorVariants[disabled ? 'disabled' : error ? 'error' : 'default']}
        >
          {endDecorator}
        </div>
      )}
    </div>
  )
})

export default Input
