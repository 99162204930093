import { assignInlineVars } from '@vanilla-extract/dynamic'
import clsx from 'clsx'
import * as React from 'react'

import { theme, ThemeTypographyKeys } from '../../theme'
import { root, rootGutter, rootNoWrap, textColor, textStyles } from './Typography.css'

const defaultVariantMapping: Record<ThemeTypographyKeys, string> = {
  textXSRegular: 'p',
  textXSBold: 'p',
  textSMRegular: 'p',
  textSMBold: 'p',
  textMDRegular: 'p',
  textMDBold: 'p',
  textLGRegular: 'p',
  textLGBold: 'p',
  textXLRegular: 'h6',
  textXLBold: 'h6',
  displayXS: 'h5',
  displaySM: 'h4',
  displayMD: 'h3',
  displayLG: 'h2',
  displayXL: 'h1',
  display2XL: 'h1',
  display3XL: 'h1',
  display4XL: 'h1',
}

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant?: ThemeTypographyKeys
  gutterBottom?: boolean
  noWrap?: boolean
}

const Typography = React.forwardRef(function Typography(
  { color, variant = 'textXSBold', gutterBottom = false, noWrap = false, ...props }: TypographyProps,
  ref
) {
  const component = defaultVariantMapping[variant]
  return React.createElement(component, {
    className: clsx(root, textStyles[variant], {
      [rootNoWrap]: noWrap,
      [rootGutter]: gutterBottom,
    }),
    ...props,
    style: { ...props.style, ...assignInlineVars({ [textColor]: color || theme.palette.black }) },
    ref,
  })
})

export default Typography

Typography.displayName = 'Typography'
